export function formatNumberWithThreeDecimalPlaces(num: number) {
    if (num === 0) {
        return "0.00";
    }
    const parts = num.toString().split('.');
    
    // Ensure there are exactly 4 digits after the decimal without rounding
    if (parts.length > 1 && parts[1].length > 4) {
        return parts[0] + '.' + parts[1].slice(0, 4);
    } else {
        return num.toFixed(4).replace(/\.?0*$/, '');
    }
}


export const formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
  });
  export const convertNumbThousand = (x?: number, max = 7): string => {
    if (!x) {
      return "0";
    }
  
    if (x < 1) {
      return x.toLocaleString("en-US", { maximumFractionDigits: max});
    }
  
    return x.toLocaleString("en-US");
  };
  
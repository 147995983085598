'use client'

import { IMAGE_ASSET } from '@/config/publicWagmiConfig';
import Image from 'next/image';
import { FC, useState } from 'react';
import { getAddress, isAddress } from 'viem';
import { SkeletonCircle } from '@/components/ui/skeleton';
import classNames from 'classnames';

export const TokenImage: FC<{ chainId: number; data: string, className?: string}> = ({
  data,
  chainId,
  className = "w-6 h-6 "
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const imageUrl = isAddress(data)
    ? `https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/${
        IMAGE_ASSET[chainId as keyof typeof IMAGE_ASSET]
      }/assets/${getAddress(data)}/logo.png`
    : data;

  return (
    <>
      {isLoading && !error && <SkeletonCircle className={className} radius={24} />}
      <Image
        src={imageUrl}
        className={classNames(className,'rounded-full', {
          'hidden': isLoading && !error,
          'block': !isLoading || error,
        })}
        alt="token-image"
        width={56}
        height={56}
        priority
        decoding='async'
        onLoad={() => setIsLoading(false)}
        onError={() => {
          setIsLoading(true);
          setError(true);
        }}
        unoptimized={true}
      />
    </>
  );
};
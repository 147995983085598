/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { useQueries } from "@tanstack/react-query";
import { arbitrum, base, bsc, mainnet } from 'viem/chains';

const fetchData = async (url: string, chainId: number) => {
    const { data } = await axios.get(url);
    return data.tokens.map((token: any) => {
        const { pairs, ...rest } = token;
        return { ...rest, chainId };  // Assign chainId to each token
    });
};

const tokenLists = [
    {
        queryKey: ['mainnet-tokens'],
        url: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/ethereum/tokenlist.json",
        chainId: mainnet.id
    },
    {
        queryKey: ['base-tokens'],
        url: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/base/tokenlist.json",
        chainId: base.id
    },
    {
        queryKey: ['arbitrum-tokens'],
        url: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/arbitrum/tokenlist.json",
        chainId: arbitrum.id
    },
    {
        queryKey: ['bsc-tokens'],
        url: "https://raw.githubusercontent.com/trustwallet/assets/refs/heads/master/blockchains/smartchain/tokenlist.json",
        chainId: bsc.id
    }
];

export const useTokens = () => {
    return useQueries({
        queries: tokenLists.map(({ queryKey, url, chainId }) => ({
            queryKey,
            queryFn: () => fetchData(url, chainId), // Pass chainId to fetchData
            staleTime: 30 * 24 * 60 * 60 * 1000, // 30 days in milliseconds
            cacheTime: 30 * 24 * 60 * 60 * 1000, // 30 days in milliseconds
        })),
    });
};

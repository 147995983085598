/* eslint-disable react-hooks/exhaustive-deps */
import { TokenImage } from '../image/token-image';
import { useConfig } from 'wagmi';
import { erc20Abi } from 'viem';
import { readContracts } from '@wagmi/core';
import debounce  from 'lodash.debounce'; // Make sure to import debounce
import { SkeletonCircle, SkeletonText } from '../ui/skeleton';
import { useAppDispatch, useAppSelector } from '@/hooks/useStore';
import { useSwapProvider } from '@/providers/swap-provider';
import { addToken } from '@/store/slices/tokensManager';
import { setDestinationToken, setSourceToken } from '@/store/slices/swapSlice';

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { Button } from '@headlessui/react';
import { ChevronDown, SearchIcon } from 'lucide-react';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '../ui/drawer';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';
import { Input } from '../ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { NetworkIcon } from '@/assets';
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandEmpty,
} from '../ui/command';
import { Chain, publicChains } from '@/config/publicWagmiConfig';
import { useTokens } from '@/hooks/useTokens';

import { FixedSizeList as List } from 'react-window';

import { Star } from 'lucide-react';

import { isAddress } from 'viem';
import { useNativeToken } from '@/hooks/useNative';
import { DEBOUNCE_DELAY } from '@/config/config';

interface NetworkItemProps {
  id: number;
  name: string;
  onSelect: (value: string) => void;
}
interface TokenInfo {
  name: string;
  symbol: string;
  decimals: number;
}
interface TokenListItem extends TokenInfo {
  address: string;
}
const NetworkItem: React.FC<NetworkItemProps> = ({ id, name, onSelect }) => (
  <CommandItem
    className="cursor-pointer data-[selected='true']:bg-[#100c18] data-[selected='true']:text-white font-medium rounded-md"
    value={`${name}__${id}`}
    onSelect={onSelect}>
    <div className="flex justify-between w-full">
      <div className="flex items-center gap-2">
        <NetworkIcon chainId={id} width={22} height={22} />
        <div className="text-white">{name}</div>
      </div>
    </div>
  </CommandItem>
);

const SearchBar: React.FC<{
  selected: number;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}> = ({ selected, setSelected, search, setSearch }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [localSearch, setLocalSearch] = useState<string>(search);

  const handleNetworkSelect = useCallback(
    (value: string) => {
      setSelected(+value.split('__')[1]);
      setOpen(false);
    },
    [setSelected]
  );

  const debouncedSetSearch = useMemo(
    () => debounce((value: string) => {
      setSearch(value);
    }, DEBOUNCE_DELAY),
    [setSearch]
  );

  const handleSearchInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setLocalSearch(value); // Update local state immediately
      debouncedSetSearch(value); // Debounce the actual search update
    },
    [debouncedSetSearch]
  );

  return (
    <div className="flex items-center justify-between mt-2 border border-white/20 px-3 py-1 md:py-0.5 rounded-full w-full">
      <div className="inline-flex gap-1.5 items-center w-full">
        <SearchIcon className="text-white/50" />
        <Input
          value={localSearch}
          onChange={handleSearchInput}
          type="text"
          placeholder="Search Token"
          className="text-base font-bold bg-transparent text-white focus:ring-0 p-0 ring-0 outline-none border-0 focus:border-transparent focus-visible:ring-0 appearance-none w-full pr-2"
        />
      </div>
      <Popover open={isOpen} onOpenChange={setOpen}>
        <PopoverTrigger className="cursor-pointer whitespace-nowrap inline-flex gap-0.5 items-center justify-center font-medium disabled:opacity-50 disabled:pointer-events-none ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 bg-[#23232f] hover:bg-[#23232f]/80 focus:bg-[#23232f]/50 h-[32px] px-2 text-xs rounded-lg">
          <NetworkIcon chainId={selected} width={24} height={24} />
          <ChevronDown className="w-4 h-4" />
        </PopoverTrigger>
        <PopoverContent
          align="end"
          className="!w-60 !p-0 !overflow-x-hidden !overflow-y-scroll scroll bg-cardBg border-cardBorder">
          <Command className="bg-cardBg text-white">
            <CommandInput
              className="border-b-cardBorder"
              placeholder="Search network"
            />
            <CommandList>
              <CommandEmpty>No network found.</CommandEmpty>
              <CommandGroup className="text-white font-medium space-y-2">
                <NetworkItem
                  id={0}
                  name="All networks"
                  onSelect={handleNetworkSelect}
                />
                {publicChains?.map((c) => (
                  <NetworkItem
                    key={c.id}
                    id={c.id}
                    name={Chain[c.id] as string}
                    onSelect={handleNetworkSelect}
                  />
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

const TokensList: React.FC<{
  ChainId: number;
  search: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSource: boolean;
}> = ({ ChainId, search, setIsOpen, isSource }) => {
  const query = useTokens();
  const { tokenList } = useAppSelector((state) => state.tokenManagerSlice);
  const { srcToken } = useAppSelector((state) => state.swapSlice);
  const dispatch = useAppDispatch();
  const { mutate } = useSwapProvider();
  const filteredTokenList = useMemo(() => {
    const chainMap: Record<number, number> = { 1: 0, 8453: 1, 42161: 2, 56: 3 };

    if (ChainId === 0) {
      // Show all chain data
      return Object.values(query)
        .flatMap((q) => q.data)
        .concat(tokenList);
    } else {
      // Show only selected chain data
      const chainTokens = tokenList.filter((t) => t.chainId === ChainId);
      return query[chainMap[ChainId]]?.data?.concat(chainTokens) || chainTokens;
    }
  }, [tokenList, query, ChainId]);

  const filteredList = useMemo(() => {
    const searchLower = search.toLowerCase();
    const filtered = filteredTokenList.filter(
      (token: TokenListItem) =>
        token?.address?.toLowerCase().includes(searchLower) ||
        token?.name?.toLowerCase().includes(searchLower) ||
        token?.symbol?.toLowerCase().includes(searchLower)
    );
    return filtered.sort((a: any, b: any) => {
      if (
        a.address.toLowerCase() === '0x0000000000000000000000000000000000000000'
      )
        return -1;
      if (
        b.address.toLowerCase() === '0x0000000000000000000000000000000000000000'
      )
        return 1;
      return 0;
    });
  }, [filteredTokenList, search]);
  const handleSelect = useCallback(
    (value: string) => {
      const [address, , , selectChainId] = value.split('__');
      const token = filteredList.find(
        (t: any) => t.address === address && t.chainId === Number(selectChainId)
      );

      if (token) {
        if (isSource) {
          dispatch(setSourceToken(token));
          mutate.setSrc(token.address, +selectChainId);
        } else {
          dispatch(setDestinationToken(token));
          mutate.setDest(token.address, +selectChainId);
        }
      }
      setIsOpen(false);
    },
    [dispatch, filteredList, isSource, mutate, setIsOpen]
  );

  const close = useCallback(() => setIsOpen(false), [setIsOpen]);
  return (
    <Command className="bg-[#23232F] text-white">
      <CommandGroup
        heading={
          <div className="flex py-2 items-center justify-start gap-1">
            <Star />
            <h4 className="font-medium text-[16px]">Tokens</h4>
          </div>
        }
        className="px-2">
        <CommandList className="text-white">
          <CommandEmpty>
            {isAddress(search.toLowerCase()) ? (
              <AddExternalToken
                address={search}
                chainId={ChainId}
                close={close}
                isSource={isSource}
              />
            ) : (
              'No asset found.'
            )}
          </CommandEmpty>
        </CommandList>
        <div className='custom-scrollbar'>
        <List
          height={400}
          itemCount={filteredList.length}
          itemSize={55}
          width="100%">
          {({ index, style }) => (
            <CommandItem
              style={style}
              key={filteredList[index].address}
              value={`${filteredList[index].address}__${filteredList[index].name}__${filteredList[index].symbol}__${filteredList[index].chainId}`}
              disabled={`${srcToken?.address}_${srcToken?.chainId}`=== `${filteredList[index].address}_${filteredList[index].chainId}`}
              onSelect={handleSelect}
              className="flex flex-row items-center flex-grow gap-4 cursor-pointer data-[selected='true']:bg-[#2C2C3B] data-[selected='true']:text-white disabled:cursor-not-allowed">
              <div className="relative">
                <TokenImage
                  chainId={filteredList[index].chainId}
                  data={
                    filteredList[index]?.logo || filteredList[index].address
                  }
                  className="h-8 w-8"
                />
                <div className="absolute bottom-0 right-0">
                  <NetworkIcon
                    width={14}
                    height={14}
                    chainId={filteredList[index].chainId}
                  />
                </div>
              </div>
              <div className="flex flex-col items-start">
                <span className="font-semibold text-white">
                  {filteredList[index].symbol}
                </span>
                <div className="text-sm text-muted-foreground hover:underline truncate ">
                  {filteredList[index].name}
                </div>
              </div>
            </CommandItem>
          )}
        </List>
        </div>
        
      </CommandGroup>
    </Command>
  );
};
export const TokenSelector: React.FC<{ isSource: boolean }> = ({
  isSource,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [open, setOpen] = useState<boolean>(false);
  const [selectedChainId, setSelectedChainId] = useState<number>(0);
  const { srcToken, destToken } = useAppSelector((state) => state.swapSlice);
  const [search, setSearch] = useState('');
  const handleOpen = useCallback((value: boolean) => {
    setOpen(value);
    setSearch('');
    setSelectedChainId(0);
  }, []);
  const token = isSource ? srcToken : destToken;

  const native = useNativeToken(
    token?.address as string,
    token?.chainId as number
  );
  const tokenImageProps = useMemo(
    () => ({
      chainId: (native?.chainId as number) || (token?.chainId as number),
      data: (native?.logo as string) || (token?.address as string),
      className: 'object-cover w-8 h-8 rounded-full',
    }),
    [native, selectedChainId, token]
  );
  if (!token) {
    return null; // or return a placeholder component
  }
  const commonButton = (
    <Button
      className="inline-flex items-center justify-center gap-1 px-2 py-1.5 text-sm sm:text-base font-medium transition-colors rounded-full cursor-pointer whitespace-nowrap bg-[#23232F] hover:bg-[#23232F]/40 focus:bg-[#23232F]/60 focus:outline-none disabled:pointer-events-none disabled:opacity-50"
      onClick={() => handleOpen(true)}>
      <div className="relative flex items-center justify-center w-8 h-8">
        <TokenImage {...tokenImageProps} />
        <div className="absolute -bottom-0.5 -right-0.5 z-10 bg-[#23232F] rounded-full p-0.5">
          <NetworkIcon width={12} height={12} chainId={token.chainId} />
        </div>
      </div>
      <span className="max-w-[4rem] sm:max-w-[6rem] truncate">
        {token.symbol}
      </span>
      <ChevronDown className="w-3 h-3 sm:w-4 sm:h-4" />
    </Button>
  );

  if (isMobile) {
    return (
      <Drawer open={open} onOpenChange={handleOpen}>
        <DrawerTrigger asChild>{commonButton}</DrawerTrigger>
        <DrawerContent className="max-w-md bg-[#23232F] border-white/15 text-white rounded-2xl">
          <DrawerHeader>
            <DrawerTitle />
            <DrawerDescription />
            <SearchBar
              selected={selectedChainId}
              setSelected={setSelectedChainId}
              search={search}
              setSearch={setSearch}
            />
          </DrawerHeader>
          <div className="px-4">
            <TokensList
              setIsOpen={setOpen}
              ChainId={selectedChainId}
              search={search}
              isSource={isSource}
            />
          </div>
        </DrawerContent>
      </Drawer>
    );
  }

  return (
    <Dialog open={open} onOpenChange={handleOpen}>
      <DialogTrigger asChild>{commonButton}</DialogTrigger>
      <DialogContent className="max-w-md bg-[#23232F] border-cardBorder text-white p-4">
        <DialogHeader>
          <DialogTitle>Select a token</DialogTitle>
          <DialogDescription>
            Choose a token from the list or search for a specific one.
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col items-center w-full justify-center gap-2">
          <SearchBar
            selected={selectedChainId}
            setSelected={setSelectedChainId}
            search={search}
            setSearch={setSearch}
          />
          <TokensList
            setIsOpen={setOpen}
            ChainId={selectedChainId}
            search={search}
            isSource={isSource}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};



export const AddExternalToken: FC<{
  address: string;
  chainId: number;
  close: () => void;
  isSource: boolean;
}> = ({ address, chainId, close, isSource }) => {
  const [tokenData, setTokenData] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const dispatch = useAppDispatch();
  const { mutate } = useSwapProvider();
  const config = useConfig();
  useEffect(() => {
    const fetchTokenData = async () => {
      setIsLoading(true);
      setIsError(false);

      const chainsToCheck = chainId === 0 ? publicChains : [{ id: chainId }];

      for (const chain of chainsToCheck) {
        try {
          const result = await readContracts(config, {
            contracts: [
              {
                address: address as `0x${string}`,
                abi: erc20Abi,
                functionName: 'decimals',
                chainId: chain.id,
              },
              {
                address: address as `0x${string}`,
                abi: erc20Abi,
                functionName: 'name',
                chainId: chain.id,
              },
              {
                address: address as `0x${string}`,
                abi: erc20Abi,
                functionName: 'symbol',
                chainId: chain.id,
              },
            ],
          });

          if (result.every((item) => item.status === 'success')) {
            setTokenData({ ...result, chainId: chain.id });
            setIsLoading(false);
            return;
          }
        } catch (error) {
          console.error(
            `Error fetching token data for chain ${chain.id}:`,
            error
          );
        }
      }

      setIsError(true);
      setIsLoading(false);
    };

    if (address) {
      fetchTokenData();
    }
  }, [address, chainId]);

  const handleAddToken = useCallback(() => {
    const token = {
      address: address,
      asset: address,
      name: tokenData[1].result,
      symbol: tokenData[2].result,
      decimals: tokenData[0].result,
      logoURI: '',
      chainId: tokenData.chainId,
    };
    dispatch(addToken(token));
    if (isSource) {
      dispatch(setSourceToken(token));
      mutate.setSrc(token.address, token.chainId);
    } else {
      dispatch(setDestinationToken(token));

      mutate.setDest(token.address, token.chainId);
    }
    close();
  }, [tokenData, dispatch]);
  if (isLoading)
    return (
      <div className="px-2">
        <div className="flex flex-row items-center flex-grow gap-4 cursor-pointer hover:bg-[#2C2C3B] hover:text-white disabled:cursor-not-allowed p-3 rounded-md">
          <SkeletonCircle radius={34} className="bg-gray-400" />
          <div className="flex flex-col items-start">
            <span className="font-semibold text-white">
              <SkeletonText className="bg-gray-400 w-36" />
            </span>
            <SkeletonText className="bg-gray-400 w-52" />
          </div>
        </div>
      </div>
    );
  if (isError || !tokenData) return <span>No asset found.</span>;

  return (
    <div onClick={handleAddToken} className="px-2">
      <div className="flex flex-row items-center flex-grow gap-4 cursor-pointer hover:bg-[#2C2C3B] hover:text-white disabled:cursor-not-allowed p-3 rounded-md">
        <TokenImage
          chainId={tokenData.chainId}
          data={address}
          className="h-8 w-8"
        />
        <div className="flex flex-col items-start">
          <span className="font-semibold text-white">
            {tokenData[2].result}
          </span>
          <div className="text-sm text-muted-foreground hover:underline truncate max-w-28">
            {tokenData[1].result}
          </div>
        </div>
      </div>
    </div>
  );
};

import { useAppSelector } from '@/hooks/useStore';
import { motion } from 'framer-motion';
import { FC, useMemo } from 'react';
import { Data } from './swap-button';
import { erc20Abi, formatEther, formatUnits } from 'viem';
import { convertNumbThousand, formatter } from '@/utils/numbers';
import { fees } from '@/config/fees';
import { useChains, useReadContracts } from 'wagmi';
import { useQuery } from '@tanstack/react-query';
import { useNativeToken } from '@/hooks/useNative';

const CACHE_DURATION_HOURS = 1;
const API_KEY = '2b603e45d0d54ea8b131970efeb4457a';

export const Fees: FC<{ data: Data | undefined }> = ({ data }) => {
  const { srcToken, destToken } = useAppSelector((state) => state.swapSlice);
  const chains = useChains();
  const chain = useMemo(() => (chainId: number) => chains.find((c) => c.id === chainId), [chains]);

  const { data: ethUSD } = useQuery({
    queryKey: ['ethUSD', srcToken?.chainId],
    queryFn: async () => {
      const response = await fetch(
        `https://public-api.birdeye.so/defi/price?address=${fees[srcToken?.chainId ?? 18].weth}`,
        {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'X-API-KEY': API_KEY,
            'x-chain': fees[srcToken?.chainId ?? 18].network,
          },
        }
      );
      return response.json();
    },
    staleTime: CACHE_DURATION_HOURS * 60 * 60 * 1000,
  });

  const isNative = useNativeToken(
    data?.estimation?.bridgeFee?.token as string,
    srcToken?.chainId as number
  );

  const { data: token } = useReadContracts({
    contracts: [
      {
        address: data?.estimation?.bridgeFee?.token as `0x${string}`,
        abi: erc20Abi,
        functionName: 'decimals',
        chainId: Number(data?.estimation?.bridgeFee?.chain),
      },
      {
        address: data?.estimation?.bridgeFee?.token as `0x${string}`,
        abi: erc20Abi,
        functionName: 'symbol',
        chainId: Number(data?.estimation?.bridgeFee?.chain),
      },
    ],
    query: {
      enabled: !isNative && !!data?.estimation?.bridgeFee?.token
    }
  });

  const priceValue = useMemo(() => {
    if (!ethUSD?.data || !srcToken?.chainId || !data?.estimation?.bridgeFee?.usd) return 0;
    const cost = ethUSD.data.value * Number(formatEther(fees[srcToken.chainId].flatFee));
    return cost + Number(data.estimation.bridgeFee.usd);
  }, [data?.estimation?.bridgeFee?.usd, ethUSD, srcToken?.chainId]);

  if (!data || !srcToken || !destToken || srcToken.chainId === destToken.chainId) return null;

  return (
    <motion.div
      className="flex flex-col gap-4 justify-between items-center my-4 text-sm w-full"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.9, duration: 0.5 }}>
      <FeeRow
        label="Market maker gas costs"
        amount={Number(formatUnits(BigInt(data.estimation.tokenIn.approximateOperatingExpense ?? 0), data.estimation.tokenIn.decimals))}
        symbol={data.estimation.tokenIn.symbol}
        usdValue={Number(data.estimation.tokenIn.approximateOperatingExpenseUsd)}
      />
      {data.estimation.bridgeFee && (
        <FeeRow
          label="Bridge Fee"
          amount={Number(formatEther(fees[srcToken.chainId].flatFee))}
          symbol={chain(srcToken.chainId)?.nativeCurrency.symbol ?? ''}
          secondaryAmount={Number(formatUnits(BigInt(data.estimation.bridgeFee.amount ?? 0), isNative ? 18 : (token?.[0].result as number ?? 18)))}
          secondarySymbol={isNative ? chain(srcToken.chainId)?.nativeCurrency.symbol : (token?.[1].result as string)}
          usdValue={priceValue}
        />
      )}
    </motion.div>
  );
};

const FeeRow: FC<{
  label: string;
  amount: number;
  symbol: string;
  secondaryAmount?: number;
  secondarySymbol?: string;
  usdValue: number;
}> = ({ label, amount, symbol, secondaryAmount, secondarySymbol, usdValue }) => (
  <div className="flex justify-between items-center w-full">
    <div className="text-gray-400">{label}</div>
    <div className="text-white">
      {convertNumbThousand(amount)} {symbol}
      {secondaryAmount !== undefined && secondarySymbol && (
        <>
          {' + '}
          {convertNumbThousand(secondaryAmount)} {secondarySymbol}
        </>
      )}
      <span className="ml-0.5 text-gray-400 text-sm">
        ~{formatter.format(usdValue)}
      </span>
    </div>
  </div>
);
import { parseEther } from 'viem';

export const fees: any = {
  42161: {
    name: 'Arbitrum',
    internalChainId: 42161,
    flatFee: parseEther('0.001'),
    weth: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    network: 'arbitrum',
  },
  56: {
    name: 'BNB Chain',
    internalChainId: 56,
    flatFee: parseEther('0.006'),
    weth: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    network: 'bnb',
  },
  1: {
    name: 'Ethereum',
    internalChainId: 1,
    flatFee: parseEther('0.001'),
    weth: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    network: 'ethereum',
  },

  8453: {
    name: 'Base',
    internalChainId: 8453,
    flatFee: parseEther('0.001'),
    weth: '0x4200000000000000000000000000000000000006',
    network: 'base',
  },
};

'use client';

import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/useStore';
import { setSwapSetting } from '@/store/slices/controllerSlice';
import { setAutoSlippage, setSlippage } from '@/store/slices/swapSlice';
import classNames from 'classnames';
import { Radio, RadioGroup } from '@headlessui/react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog';
import { Label } from '../ui/label';
import { HoverCard } from '../ui/hover-card';
import { typographyVariants } from '../ui/typographyVariants';
import { Switch } from '../ui/switch';
import { Collapsible } from '../ui/collapsible';
import { Toggle } from '../ui/toggle';
import { Separator } from '../ui/separator';
import { TextField } from '../ui/text-field';

const TABS = ['0.1', '0.5', '1'];
const MAX_SLIPPAGE = 50;

export const SwapSettings = () => {
  const { isSwapSettingOpen } = useAppSelector((store) => store.controllerSlice);
  const { auto_slippage, slippage, DEFAULT_SLIPPAGE } = useAppSelector((store) => store.swapSlice);
  const dispatch = useAppDispatch();

  const isDangerous = useMemo(() => {
    if(auto_slippage) {
      return false
    }
    const slippageNum = +slippage;
    return (slippageNum >= 1.3) || (slippageNum <= 0.05 && slippageNum > 0);
  }, [auto_slippage, slippage]);

  const handleSlippageChange = useCallback((value: string) => {
    dispatch(setSlippage(Number(value)));
  }, [dispatch]);

  const handleAutoSlippageToggle = useCallback((checked: boolean) => {
    dispatch(setAutoSlippage(checked));
  }, [dispatch]);

  const handleCustomSlippageChange = useCallback((value: string) => {
    const numValue = Number(value);
    if (numValue <= MAX_SLIPPAGE) {
      dispatch(setSlippage(numValue));
    }
  }, [dispatch]);

  return (
    
      <Dialog
      open={isSwapSettingOpen}
      onOpenChange={(e) => dispatch(setSwapSetting(e))}
    >
      <DialogContent className="max-w-96 bg-[#01010E] text-white border-none gradient-border-wrapper rounded-2xl">
        <DialogHeader>
          <DialogTitle>Settings</DialogTitle>
          <DialogDescription>Adjust to your personal preferences.</DialogDescription>
        </DialogHeader>
        <div className="-ml-6 h-px border-b border-white/20 w-[112%]" />
        <div className="flex flex-col gap-4">
          <HoverCard openDelay={0} closeDelay={0}>
            <div className="rounded-lg">
              <div className="flex justify-between items-center gap-4">
                <div className="flex flex-col gap-2">
                  <Label>Automatic Slippage Tolerance</Label>
                  <span className={typographyVariants({ variant: 'muted', className: 'text-sm' })}>
                    Turn off automatic slippage tolerance <br /> to adjust the value.
                  </span>
                </div>
                <Switch
                  className="data-[state=checked]:bg-sgs-gradient"
                  checked={auto_slippage}
                  onCheckedChange={handleAutoSlippageToggle}
                />
              </div>
              <div className="my-4 h-px w-full bg-white/10" />
            </div>
            <div className="flex justify-between gap-[60px]">
              <div className="flex flex-col gap-2">
                <Label className="flex items-center gap-1">Slippage</Label>
                {isDangerous && (
                  <span className="text-sm text-red-500 mb-2">
                    {+slippage <= 0.05 && +slippage >= 0
                      ? 'Your transaction may be reverted due to low slippage tolerance'
                      : 'Your transaction may be frontrun due to high slippage tolerance'}
                  </span>
                )}
              </div>
              <span className={classNames(isDangerous ? '!text-red-500' : 'text-gray-300', 'text-sm font-semibold')}>
                {auto_slippage ? `${DEFAULT_SLIPPAGE}%` : `${slippage}%`}
              </span>
            </div>
            {!auto_slippage && (
              <Collapsible open={!auto_slippage}>
                <div className="flex gap-2 p-1 items-center border border-white/10 rounded-xl bg-[#232335]">
                  <RadioGroup value={String(slippage)} onChange={handleSlippageChange}>
                    <div className="flex gap-1 items-center">
                      {TABS.map((tab) => (
                        <Radio
                          key={tab}
                          value={tab}
                          as={Toggle}
                          size="sm"
                          pressed={String(slippage) === tab}
                          className="h-[40px] data-[state=on]:bg-[#EF4D36] data-[state=on]:text-white hover:bg-gray-600/70 hover:text-white"
                        >
                          {tab}%
                        </Radio>
                      ))}
                    </div>
                  </RadioGroup>
                  <Separator orientation="vertical" className="min-h-[36px]" />
                  <TextField
                    type="number"
                    value={slippage}
                    onValueChange={handleCustomSlippageChange}
                    placeholder="Custom"
                    id="slippage-tolerance"
                    maxDecimals={2}
                    className="bg-[#372f47] text-white group-hover::bg-[#372f47] group-focus:bg-[#372f47]"
                    unit="%"
                  />
                </div>
              </Collapsible>
            )}
          </HoverCard>
        </div>
      </DialogContent>
    </Dialog>
    
  );
};
import { NATIVE_TOKEN } from '@/config/config';
import { useAppDispatch } from '@/hooks/useStore';
import { setInputAmount } from '@/store/slices/swapInputSlice';
import { formatNumberWithThreeDecimalPlaces } from '@/utils/numbers';
import { Wallet2 } from 'lucide-react';
import { FC, useCallback, useMemo } from 'react';
import { erc20Abi, formatUnits } from 'viem';
import { useAccount, useBalance, useReadContracts } from 'wagmi';

export const WalletBalance: FC<{ tokenAddress: string; chainId: number; type: 'INPUT' | 'OUTPUT' }> = ({
  tokenAddress,
  chainId,
  type,
}) => {
  const { address } = useAccount();
  const dispatch = useAppDispatch();
  const isNative = tokenAddress === NATIVE_TOKEN
  // Fetch native token balance only if tokenAddress is NATIVE_TOKEN
  const { data: native_balance } = useBalance({
    address,
    chainId,
    query: { enabled: !!address && tokenAddress === NATIVE_TOKEN },
  });

  // Fetch token data for ERC-20 tokens
  const { data: token_balance } = useReadContracts({
    allowFailure: false,
    contracts: [
      {
        address: tokenAddress as `0x${string}`,
        abi: erc20Abi,
        functionName: 'balanceOf',
        args: [address as `0x${string}`],
        chainId,
      },
      {
        address: tokenAddress as `0x${string}`,
        abi: erc20Abi,
        functionName: 'decimals',
        chainId,
      },
      {
        address: tokenAddress as `0x${string}`,
        abi: erc20Abi,
        functionName: 'symbol',
        chainId,
      },
    ],
    query: {
      enabled: !isNative && !!address
    }
  });

  // Memoize the formatted native balance
  const formattedNativeBalance = useMemo(
    () =>
      formatNumberWithThreeDecimalPlaces(
        +formatUnits(native_balance?.value ?? BigInt(0), native_balance?.decimals ?? 18)
      ),
    [native_balance]
  );

  // Memoize the formatted ERC-20 token balance
  const formattedTokenBalance = useMemo(
    () =>
      formatNumberWithThreeDecimalPlaces(
        +formatUnits(token_balance?.[0] ?? BigInt(0), token_balance?.[1] ?? 18)
      ),
    [token_balance]
  );

  // Function to set the input amount when balance is clicked
  const setBalance = useCallback(
    (balance: string) => {
      if (type === 'INPUT') {
        dispatch(setInputAmount({
          amount: balance,
        }));
      }
    },
    [dispatch, type]
  );

  // Return 0.00 if the user has no address
  if (!address) {
    return (
      <span className="inline-flex items-center gap-1 cursor-pointer">
        <Wallet2 className="w-4 h-4" />
        <span>0.00</span>
      </span>
    );
  }

  // If tokenAddress is native, return native balance
  if (isNative) {
    return (
      <span
        onClick={() => setBalance(formattedNativeBalance)} // Fix: wrap setBalance in a function
        className="inline-flex items-center gap-1 cursor-pointer"
      >
        <Wallet2 className="w-4 h-4" />
        {formattedNativeBalance}
      </span>
    );
  }

  // Return ERC-20 token balance
  return (
    <span
      onClick={() => setBalance(formattedTokenBalance)} 
      className="inline-flex items-center gap-1 cursor-pointer"
    >
      <Wallet2 className="w-4 h-4" />
      {formattedTokenBalance}
    </span>
  );
};

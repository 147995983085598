'use client';

import Swap from '@/components/swap';
import { SwapSettings } from '@/components/swap-setting';

export default function Home() {
  return (
    <div className="lg:p-2 mt-8 mb-[86px]">
      <Swap />
      <SwapSettings />
    </div>
  );
}

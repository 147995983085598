import { NATIVE_TOKEN, NATIVE_TOKENS } from '@/config/config';

export const useNativeToken = (address: string, chainId: number,) => {
  if (NATIVE_TOKEN !== address) {
    return null;
  } else {
    const data = NATIVE_TOKENS[chainId as keyof typeof NATIVE_TOKENS];
    return data;
  }
};

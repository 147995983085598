import { useSwapProvider } from '@/providers/swap-provider';
import { useAppDispatch, useAppSelector } from './useStore';
import { swapTokens } from '@/store/slices/swapSlice';
import { store } from '@/store';
import { setInputAmount } from '@/store/slices/swapInputSlice';

export const useSwapTokens = () => {
  const dispatch = useAppDispatch();
  const { mutate } = useSwapProvider();
  const {inputAmount} = useAppSelector((state) => state.swapInputSlice)

  const handleSwapTokens = () => {
    // Access the updated state after the swap
    dispatch(swapTokens());
    const state = store.getState().swapSlice;
    // Update the context
    if (state.srcToken && state.destToken) {
      mutate.setSrc(state.srcToken.address, state.srcToken.chainId);
      mutate.setDest(state.destToken.address, state.destToken.chainId);
      dispatch(setInputAmount({
        amount: inputAmount
      }))
    }
  };

  return handleSwapTokens;
};
